import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import chroma from "chroma-js";
import { clearDB } from "@/repo/appSettingRepo";
import config from "../config/app";
import permissionsHelper from "./permissionsHelper";
export default {
  name: "authHelper",
  components: {
    permissionsHelper,
  },
  data() {
    return {
      currentWorkspace: {},
      currentWorkspaceId: "",
      userWorkspaceList: [],
      brandingInfo: {},
      globalFilterLoading: false,
      globalFilterLimit: 30,
      globalFilterPage: 1,
      globalFilterSearch: "",
      globalFilterEntity: null,
      isSearching: false,
      globalFilterHasMoreData: false,
      allGlobalFilterEntityData: [],
      activeRole: "",
    };
  },
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getAuthenticationStatus",
      "getWorkspaceList",
      "getGlobalFiltervalue",
      "getUserTypeEmails",
      "",
    ]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo",
      "getSingleCompanyLogo",
      "getContactLoginSlug",
      "getIsMobile",
    ]),
    ...mapGetters("entities", ["getEntityRecordsForTable"]),
    globalFilterExists() {
      if (this.getCompanyDetails?.global_filters) {
        let gFilter = this.getCompanyDetails.global_filters.find(
          (e) => e.include_in_topbar
        );
        return gFilter?.field_type ? gFilter : null;
      }
      return null;
    },
    isSSOUser() {
      return this.getAuthenticatedUser && this.getAuthenticatedUser.sso_user_id;
    },
  },
  methods: {
    async changeWorkspace(newWorkspaceId, isNotFromTopBar = false) {
      try {
        Swal.close();
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure to switch the workspace?",
          icon: "warning",
          showCancelButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$bus.$emit("load-component", true, "Switching workspace");
            await this.$store.dispatch("auth/switchWorkspace", {
              currentWorkspaceId: newWorkspaceId,
            });
            if (this.getAuthenticationStatus) {
              this.$store.commit("errors/reset", null, {
                root: true,
              });
              this.currentWorkspace =
                this.getAuthenticatedUser.current_workspace;
              this.$store.commit(
                "auth/setActiveWorkspace",
                this.currentWorkspace,
                {
                  root: true,
                }
              );
              this.$store.commit("auth/setAppMode", false, {
                root: true,
              });
              await Promise.all([
                clearDB(),
                this.fetchUserDataAfterSwitch(),
                this.getCompanyInfo(true),
                this.$store.dispatch("auth/fetchProfile"),
              ]);
              if (this.isWorkspaceIsBusiness(this.getActiveWorkspace)) {
                await this.getCompanyUserType(
                  this.getAuthenticatedUser.activeRole.userType_id
                );
              }
              // let path = this.isWorkspaceIsBusiness(this.getActiveWorkspace) ? "/entity?key=" : "/dashboard?route=";
              let path = "/dashboard?route=";
              await this.$router.push({
                path: `${path}${Math.floor(Math.random() * (1000 - 1)) + 100}&from_login=true`,
              });
              window.location.reload();
              Swal.close();
            }
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            if (!isNotFromTopBar) {
              await this.$router.push({
                path: `/entity?route=${
                  Math.floor(Math.random() * (1000 - 1)) + 100
                }`,
              });
              await this.$store.dispatch(
                "auth/fetchUsersWithEmail",
                this.getAuthenticatedUser.email
              );
            }
            this.currentWorkspaceId = this.currentWorkspace.company_id;
            this.setActiveRole();
          }
        });
      } catch (err) {
        this.$bus.$emit("load-component", false, "");
        console.log("changeWorkspace", err);
      }
    },
    async setActiveRole() {
      this.activeRole =
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.activeRole &&
        this.getAuthenticatedUser.activeRole.userType_id
          ? this.getAuthenticatedUser.activeRole.userType_id
          : "";
    },
    async fetchUserDataAfterSwitch() {
      // this.loading = true;
      await this.$store.dispatch(
        "auth/fetchUsersWithEmail",
        this.getAuthenticatedUser.email
      );
      if (this.getWorkspaceList) {
        let info = await this.updateIndividualWorkspace(
          this.getActiveWorkspace
        );
        this.currentWorkspace = info.currentWorkspace;
        this.userWorkspaceList = info.userWorkspaceList;
      } else {
        this.userWorkspaceList = [];
      }
    },
    async getCompanyInfo(fetchNewData) {
      // this.loading = true;
      try {
        if (fetchNewData) {
          await Promise.all([
            this.$store.dispatch(
              "company/fetchCompany",
              this.getActiveWorkspace.company_id
            ),
            this.$store.dispatch(
              "company/fetchCompanyLogo",
              this.getActiveWorkspace.company_id
            ),
          ]);
        }
        if (this.getCompanyDetails) {
          this.brandingInfo = {
            ...this.getCompanyDetails,
          };
          await Promise.all([
            this.applyTheme(),
            this.fetchGlobalFilterEntityData(),
          ]);
        }
      } catch (e) {
        this.$bus.$emit("load-component", { value: false });
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    async applyTheme() {
      // Apply theme using company info
      // Ensure that this.companyInfo is set before applying theme
      document.documentElement.style.setProperty(
        "--primary-color",
        this.getCompanyDetails.dialog_header_color
      );
      document.documentElement.style.setProperty(
        "--icon-color",
        this.getCompanyDetails.menu_icon_color
      );
      document.documentElement.style.setProperty(
        "--icon-hover-color",
        this.getCompanyDetails.menu_icon_hover_color
      );
      document.documentElement.style.setProperty(
        "--tabs-color",
        this.getCompanyDetails.tabs_color
      );
      document.documentElement.style.setProperty(
        "--tabs-hover-color",
        this.getCompanyDetails.tabs_hover_color
      );
      document.documentElement.style.setProperty(
        "--application-background-color",
        this.getCompanyDetails.application_background_color
      );
      document.documentElement.style.setProperty(
        "--primary-contrast-color",
        this.getCompanyDetails.dialog_header_text_color
      );
      document.documentElement.style.setProperty(
        "--sidemenu-background-color",
        this.getCompanyDetails.sidemenu_background
      );
      document.documentElement.style.setProperty(
        "--sidemenu-text-color",
        this.getCompanyDetails.sidemenu_text
      );
      document.documentElement.style.setProperty(
        "--topbar-text-color",
        this.getCompanyDetails.topbar_text_color
      );
      const primaryColor = getComputedStyle(document.documentElement)
        .getPropertyValue("--primary-color")
        .trim();
      const lighterPrimaryColor = chroma(primaryColor).brighten(0.5).hex();
      const lighterbackgroundColor = chroma(primaryColor).brighten(1.0).hex();
      const darkerPrimaryColor = chroma(primaryColor).darken(0.5).hex();
      document.documentElement.style.setProperty(
        "--darker-primary-color",
        darkerPrimaryColor
      );
      document.documentElement.style.setProperty(
        "--lighter-primary-color",
        lighterPrimaryColor
      );
      document.documentElement.style.setProperty(
        "--lighter-background",
        lighterbackgroundColor
      );
      // document.documentElement.style.setProperty('--Hover-color-inputfield', this.companyInfo.menubackground);
      // document.documentElement.style.setProperty('--text-secondary-color', 'white');

      const secondaryColor = getComputedStyle(document.documentElement)
        .getPropertyValue("--secondary-color")
        .trim();

      const darkerSecondaryColor = chroma(secondaryColor).brighten(0.3).hex();

      document.documentElement.style.setProperty(
        "--darker-secondary-color",
        darkerSecondaryColor
      );

      if (this.getCompanyDetails.default_font_type) {
        document.documentElement.style.setProperty(
          "--font-family",
          this.getCompanyDetails.default_font_type
        );
      }

      /*** Based On company Profile setting, Top bar color will apply - Block Start */
      if (this.getCompanyDetails?.menubackground) {
        this.loginPageColor = this.getCompanyDetails.menubackground;
      } else {
        this.loginPageColor = "#1B487E";
      }
      /*** Based On company Profile setting, Top bar color will apply - Block end */
    },
    async fetchGlobalFilterEntityData() {
      if (this.globalFilterExists?.entity_id) {
        this.globalFilterLoading = true;
        let params = {
          entity_id: this.globalFilterExists.entity_id,
          template_fields_data: [],
          filters: [],
          relationship: {},
          limit: this.globalFilterLimit,
          page: this.globalFilterPage,
        };
        if (this.globalFilterSearch) {
          params.search_string = this.globalFilterSearch;
          params.primary_search_fields = this.getSearchPrimaryFields(0);
        }
        await this.$store.dispatch(
          "entities/fetchEntityRecordsForTable",
          params
        );
        if (this.isSearching || !this.loadMore) {
          this.allGlobalFilterEntityData = [];
        }
        this.loadMore = false;
        if (this.getEntityRecordsForTable) {
          this.globalFilterHasMoreData = this.getEntityRecordsForTable.hasMore;
          this.allGlobalFilterEntityData = [
            ...(this.allGlobalFilterEntityData || []),
            ...this.getEntityRecordsForTable.data,
          ];
          this.globalFilterEntity =
            this.getEntityRecordsForTable.selectedEntity;
          if (this.getGlobalFiltervalue) {
            this.globalFilter = this.getGlobalFiltervalue;
          }
          if (this.globalFilter) {
            let existed = this.allGlobalFilterEntityData.find(
              (e) => e._id == this.globalFilter
            );
            if (!existed) {
              this.globalFilter = "";
              this.$store.commit("auth/setGlobalFiltervalue", "", {
                root: true,
              });
            }
          }
        }
        this.globalFilterLoading = false;
      }
    },
    getSearchPrimaryFields(index) {
      let result = [];
      if (
        this.globalFilterEntity?.primaryFields?.[index]?.template_id &&
        this.globalFilterEntity?.primaryFields?.[index]?.key
      ) {
        result = [
          this.globalFilterEntity?.primaryFields?.[index]?.template_id +
            "." +
            this.globalFilterEntity?.primaryFields?.[index]?.key,
        ];
      }
      return result;
    },
    async changeWorkspaceRole(newWorkspaceId, activeRole) {
      this.$bus.$emit("load-component", false);
      Swal.close();
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure to switch the Role?",
        icon: "warning",
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$bus.$emit("load-component", true);
          await this.$store.dispatch("auth/switchWorkspace", {
            currentWorkspaceId: newWorkspaceId,
            activeRole: activeRole,
          });
          if (this.getAuthenticationStatus) {
            this.$store.commit("errors/reset", null, {
              root: true,
            });
            this.currentWorkspace = this.getAuthenticatedUser.current_workspace;
            this.$store.commit(
              "auth/setActiveWorkspace",
              this.currentWorkspace,
              {
                root: true,
              }
            );
            await Promise.all([
              this.fetchUserDataAfterSwitch(),
              this.getCompanyInfo(true),
              this.$store.dispatch("auth/fetchProfile"),
            ]);
            if (this.isWorkspaceIsBusiness(this.getActiveWorkspace)) {
              await this.getCompanyUserType();
            }
            await this.$router.push({
              path: `/dashboard?route=${
                Math.floor(Math.random() * (1000 - 1)) + 100
              }`,
            });
            window.location.reload();
            Swal.close();
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.currentWorkspaceId = this.currentWorkspace.company_id;
          this.setActiveRole();
        }
      });
    },
    getStyleForHorMenu() {
      if (
        this.getCompanyDetails &&
        this.getCompanyDetails.menuType &&
        this.getCompanyDetails.menuType == "Horizontal"
      ) {
        // return 'position: relative !important;';
        this.isHorMenu = false;
        document.getElementById("MainLayout").style.marginTop = "80px";
        document.getElementById("MainLayout").style.paddingTop = "80px";
        document.getElementById("MainLayout").style.paddingLeft = "30px";
        document.getElementById("MainLayout").style.paddingRight = "10px";
        return (document.getElementById("topBarHeader").style.position =
          "fixed");
      } else if (this.getIsMobile) {
        document.getElementById("MainLayout").style.marginTop = "140px";
        document.getElementById("MainLayout").style.paddingTop = "80px";
        document.getElementById("MainLayout").style.paddingLeft = "30px";
        document.getElementById("MainLayout").style.paddingRight = "10px";
        return (document.getElementById("topBarHeader").style.position =
          "fixed");
      } else {
        // return '';
        document.getElementById("MainLayout").style.marginTop = "67px";
        document.getElementById("MainLayout").style.paddingTop = "30px";
        document.getElementById("MainLayout").style.paddingLeft = "100px";
        document.getElementById("MainLayout").style.paddingRight = "30px";

        return (document.getElementById("topBarHeader").style.position =
          "fixed");
      }
    },
    async getSubscriptionHistory() {
      try {
        let email = "info@esigns.io";
        await this.$store.dispatch("paymentGateway/fetchSubscriptionInfo", {
          email,
        });
      } catch (error) {
        console.log("getSubscriptionHistory", error);
      }
    },
    async handleLogoutInfo() {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Due to inactivity for a prolonged period, your session has been automatically logged out for security purposes. Please feel free to log in again when you're ready!",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          let timerInterval;
          Swal.fire({
            title: "",
            html: "Please wait ..... ",
            timer: 2000,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
              Swal.showLoading();
            },
            willClose: () => {
              clearInterval(timerInterval);
              this.logout();
            },
          });
        }
      });
    },
    async getUserTypeEmailsList(userTypes) {
      try {
        if (!userTypes.length) {
          return [];
        }
        let AllUserTypeIds = userTypes.map((e) => e._id);
        let params = {
          userTypes: AllUserTypeIds,
        };
        await this.$store.dispatch("auth/fetchUserTypeEmails", params);
        if (this.getUserTypeEmails) {
          return this.getUserTypeEmails.data;
        }
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: this.getUserTypeEmailsErrors,
        });
      }
    },
    logout() {
      localStorage.removeItem("botId");
      localStorage.removeItem("token");
      window.bus.$emit("chatbot");
      let timerInterval;
      Swal.fire({
        title: "",
        html: "E-Signs signing off....",
        timer: 3000,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading();
          localStorage.removeItem(config.APP_NAME + "_" + config.APP_VERSION);
          localStorage.clear();
          if (this.isSSOUser) {
            window.location.href =
              this.serverBaseURL +
              "/logout/client?redirect_url=" +
              this.baseURL;
            return;
          } else {
            this.resetStore();
          }
        },
        willClose: () => {
          clearInterval(timerInterval);
          this.resetStore();
          if (this.isSSOUser) {
            window.location.href =
              this.serverBaseURL +
              "/logout/client?redirect_url=" +
              this.baseURL;
            return;
          }
          clearDB(true);
          this.$router.push({
            path: "/signin",
          });
        },
      });
    },
    resetStore() {
      this.$store.commit("auth/resetAuthData", null, {
        root: true,
      });
      this.$store.commit("sso/resetAuthData", null, {
        root: true,
      });
      this.$store.commit("entityGroups/resetData", null, {
        root: true,
      });
      this.$store.commit("menuManagementV2/resetData", null, {
        root: true,
      });
      this.$store.commit("menuManagement/resetData", null, {
        root: true,
      });
      this.$store.commit("globalVariables/resetData", null, {
        root: true,
      });
      this.$store.commit("repeatableTemplates/resetData", null, {
        root: true,
      });
      this.$store.commit("templatesData/resetData", null, {
        root: true,
      });
      this.$store.commit("entities/resetData", null, {
        root: true,
      });
      this.$store.commit("companyTemplates/resetData", null, {
        root: true,
      });
      this.$store.commit("templateWorkflow/resetData", null, {
        root: true,
      });
      this.$store.commit("company/resetData", null, {
        root: true,
      });
      this.$store.commit("userStatus/resetData", null, {
        root: true,
      });
      this.$store.commit("subscription/resetData", null, {
        root: true,
      });
      this.$store.commit("templates/resetData", null, {
        root: true,
      });
      this.$store.commit("settings/resetData", null, {
        root: true,
      });
      this.$store.commit("dashboard/resetData", null, {
        root: true,
      });
      this.$store.commit("contactTypes/resetData", null, {
        root: true,
      });
      this.$store.commit("documentNotes/resetData", null, {
        root: true,
      });
      this.$store.commit("configurableDocuments/resetData", null, {
        root: true,
      });
      this.$store.commit("contacts/resetData", null, {
        root: true,
      });
      this.$store.commit("s3FileUpload/resetData", null, {
        root: true,
      });
      this.$store.commit("documents/resetData", null, {
        root: true,
      });
      this.$store.commit("errors/resetData", null, {
        root: true,
      });
      this.$store.commit("fileUpload/resetData", null, {
        root: true,
      });
      this.$store.commit("errors/reset", null, {
        root: true,
      });

      this.$store.commit("employeeData/reset", null, {
        root: true,
      });
      this.$store.commit("employeeDocuments/reset", null, {
        root: true,
      });
      clearDB(true);
    },
  },
};
